<template>
	<div class="timer">
		<p
			v-if="showReport"
			class="timer__text text-xl">
			{{ textAction }}
			<span class="timer__time">{{ getMinutes }} : {{ getSeconds }}</span>
		</p>

		<Button
			v-else
			class="text-xl"
			label="Отправить код повторно"
			link
			@click="resend()" />
	</div>
</template>

<script setup lang="ts">
	const props = defineProps({
		textAction: {
			type: String,
			required: false,
			default: "",
		},
		secondsValue: {
			type: Number,
			required: false,
			default: 5,
		},
	});
	const timerValue: any = ref(null);
	const seconds = ref(props.secondsValue);
	const showReport = ref(true);

	const getMinutes = computed(() => Math.floor(seconds.value / 60));
	const getSeconds = computed(() => {
		const zero = Math.floor(seconds.value % 60) < 10 ? "0" : "";
		return zero + Math.floor(seconds.value % 60);
	});

	const startTimer = () => {
		timerValue.value = setInterval(() => {
			if (!seconds.value) {
				clearInterval(timerValue);
				showReport.value = false;
				return;
			}

			seconds.value -= 1;
		}, 1000);
	};

	onMounted(startTimer);

	const resend = () => {
		// toDo(maksta): Добавить вызов метода запроса кода после реализации метода на бэк
		seconds.value = props.secondsValue;
		showReport.value = true;
	};
</script>

<style scoped lang="scss">
	@import "timer";
</style>
